import {
  InsertLink,
  Settings,
  AvTimer,
  FormatPaint,
  FitnessCenter,
  Fingerprint,
} from '@material-ui/icons';

const ModificationList = {
  'Welding & Fabrication': [
    InsertLink,
    '/services/land-rover-welding-fabrication',
  ],
  'Parts & enhancements': [Settings, '/services/land-rover-parts-enhancements'],
  'Engine tuning': [AvTimer, '/services/land-rover-engine-tuning'],
  'Galvanised chassis fitting': [
    FitnessCenter,
    '/services/land-rover-galvanised-chassis-fitting',
  ],
  'Bespoke projects': [Fingerprint, '/services/land-rover-bespoke-projects'],
  'Bodywork repair & painting': [
    FormatPaint,
    '/services/land-rover-bodywork-repair-painting',
  ],
};

export default ModificationList;
