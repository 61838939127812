import { PermPhoneMsg, AttachMoney, Fingerprint } from '@material-ui/icons';

const SalesList = {
  'Consultation & advice': [
    PermPhoneMsg,
    '/services/land-rover-consultation-advice',
  ],
  'Car Sales': [AttachMoney, '/services/land-rover-sales'],
  'Bespoke projects': [Fingerprint, '/services/land-rover-bespoke-projects'],
};

export default SalesList;
