import {
  InsertLink,
  FormatPaint,
  Restore,
  FitnessCenter,
} from '@material-ui/icons';

const RestorationList = {
  'Welding & Fabrication': [InsertLink, '/services/land-rover-welding-fabrication'],
  'Galvanised chassis fitting': [FitnessCenter, '/services/land-rover-galvanised-chassis-fitting'],
  'Range Rover Restoration': [Restore, '/services/range-rover-restoration'],
  'Land Rover Restoration': [Restore, '/services/land-rover-restoration'],
  'Bodywork repair & painting': [FormatPaint, '/services/land-rvoer-bodywork-repair-painting'],
};

export default RestorationList;
