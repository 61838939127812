import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import {
  Dashboard,
  Build,
  Settings,
  ShoppingCart,
  Restore,
} from '@material-ui/icons';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import NavPills from 'components/NavPills/NavPills.jsx';
import RenderListWithIcons from 'components/RenderListWithIcons/RenderListWithIcons.jsx';
import sharedStyle from 'assets/jss/material-kit-react/views/sharedSections/sharedStyle.jsx';
import FullServiceList from 'components/Pages/AllServices/Sections/FullServiceList.jsx';
import RestorationList from 'components/Pages/AllServices/Sections/RestorationList.jsx';
import ServicingList from 'components/Pages/AllServices/Sections/ServicingList.jsx';
import ModificationList from 'components/Pages/AllServices/Sections/ModificationList.jsx';
import SalesList from 'components/Pages/AllServices/Sections/SalesList.jsx';

class NavTabs extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="navigation-pills">
            <NavPills
              color="primary"
              alignCenter
              tabs={[
                {
                  tabButton: 'All',
                  tabIcon: Dashboard,
                  tabContent: (
                    <span>
                      <div className={classes.sections}>
                        <div className={classes.container}>
                          <div className={classes.centerContainer}>
                            <GridContainer spacing={30} alignItems="center">
                              <RenderListWithIcons
                                ServiceList={FullServiceList}
                              />
                            </GridContainer>
                          </div>
                        </div>
                      </div>
                    </span>
                  ),
                },
                {
                  tabButton: 'Restoration',
                  tabIcon: Restore,
                  tabContent: (
                    <span>
                      <div className={classes.sections}>
                        <div className={classes.container}>
                          <div className={classes.centerContainer}>
                            <GridContainer spacing={30} alignItems="center">
                              <RenderListWithIcons
                                ServiceList={RestorationList}
                              />
                            </GridContainer>
                          </div>
                        </div>
                      </div>
                    </span>
                  ),
                },
                {
                  tabButton: 'Servicing',
                  tabIcon: Build,
                  tabContent: (
                    <span>
                      <div className={classes.sections}>
                        <div className={classes.container}>
                          <div className={classes.centerContainer}>
                            <GridContainer spacing={30} alignItems="center">
                              <RenderListWithIcons
                                ServiceList={ServicingList}
                              />
                            </GridContainer>
                          </div>
                        </div>
                      </div>
                    </span>
                  ),
                },
                {
                  tabButton: 'Modification',
                  tabIcon: Settings,
                  tabContent: (
                    <span>
                      <div className={classes.sections}>
                        <div className={classes.container}>
                          <div className={classes.centerContainer}>
                            <GridContainer spacing={30} alignItems="center">
                              <RenderListWithIcons
                                ServiceList={ModificationList}
                              />
                            </GridContainer>
                          </div>
                        </div>
                      </div>
                    </span>
                  ),
                },
                {
                  tabButton: 'Sales',
                  tabIcon: ShoppingCart,
                  tabContent: (
                    <span>
                      <div className={classes.sections}>
                        <div className={classes.container}>
                          <div className={classes.centerContainer}>
                            <GridContainer spacing={30} alignItems="center">
                              <RenderListWithIcons ServiceList={SalesList} />
                            </GridContainer>
                          </div>
                        </div>
                      </div>
                    </span>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(sharedStyle)(NavTabs);
