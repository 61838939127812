import {
  Build,
  PermPhoneMsg,
  InsertLink,
  Settings,
  AvTimer,
  FormatPaint,
  FitnessCenter,
  ShoppingCart,
  Fingerprint,
  CheckBox,
  Warning,
  Restore,
  AcUnit,
  Album,
  AllOut,
} from '@material-ui/icons';

const FullServiceList = {
  'Welding & Fabrication': [InsertLink, '/services/land-rover-welding-fabrication'],
  'Parts & enhancements': [Settings, '/services/land-rover-parts-enhancements'],
  'Engine tuning': [AvTimer, '/services/land-rover-engine-tuning'],
  'Galvanised chassis fitting': [FitnessCenter, '/services/land-rover-galvanised-chassis-fitting'],
  'Air Conditioning Services': [AcUnit, '/services/land-rover-air-conditioning-services'],
  Tyres: [Album, '/services/land-rover-tyres'],
  'Four wheel alignment': [AllOut, '/services/land-rover-wheel-alignment'],
  'Bespoke projects': [Fingerprint, '/services/land-rover-bespoke-projects'],
  'Bodywork repair & painting': [FormatPaint, '/services/land-rover-bodywork-repair-painting'],
  'Defender Servicing': [Build, '/services/land-rover-defender-servicing'],
  'Discovery Servicing': [Build, '/services/land-rover-discovery-servicing'],
  'Range Rover Servicing': [Build, '/services/range-rover-servicing'],
  'MOT Preparation': [CheckBox, '/services/land-rover-mot-preparation'],
  'Consultation & advice': [PermPhoneMsg, '/services/land-rover-consultation-advice'],
  'Car Sales': [ShoppingCart, '/services/land-rover-sales'],
  Diagnostics: [Warning, '/services/land-rover-diagnostics'],
  'Land Rover Restoration': [Restore, '/services/land-rover-restoration'],
  'Range Rover Restoration': [Restore, '/services/range-rover-restoration'],
};

export default FullServiceList;
