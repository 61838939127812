import { Build, CheckBox, AcUnit, Album, AllOut } from '@material-ui/icons';

const ServicingList = {
  'Defender Servicing': [Build, '/services/land-rover-defender-servicing'],
  'Discovery Servicing': [Build, '/services/land-rover-discovery-servicing'],
  'Range Rover Servicing': [Build, '/services/range-rover-servicing'],
  'MOT Preparation': [CheckBox, '/services/land-rover-mot-preparation'],
  'Air Conditioning Services': [AcUnit, '/services/land-rover-air-conditioning-services'],
  Tyres: [Album, '/services/land-rover-tyres'],
  'Four wheel alignment': [AllOut, '/services/land-rover-wheel-alignment'],
};

export default ServicingList;
