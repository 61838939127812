import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import sharedStyle from 'assets/jss/material-kit-react/views/sharedSections/sharedStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

class Intro extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div id="Intro">
            <GridContainer spacing={16}>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.centerContainer}>
                  <h3>
                    As Land Rover and Range Rover specialists we offer a wide
                    variety of services. Please see the full list below, or get
                    in contact with our friendly team.
                  </h3>
                </div>
                <div>
                  <h2 className={classes.title}>Select a category</h2>
                  <div className={classes.verticleLine} />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(sharedStyle)(Intro);
