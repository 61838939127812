import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Parallax from 'components/Parallax/Parallax.jsx';
import HeaderLinks from 'components/Header/HeaderLinks.jsx';
import DefaultPageStyle from 'assets/jss/material-kit-react/views/DefaultPage.jsx';
import image from 'assets/img/series-land-rover-restoration.jpg';
import ContactSnippet from 'components/ContactSnippet/ContactSnippet.jsx';
import { Helmet } from 'react-helmet';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import withRoot from '../../../utils/withRoot';
import NavTabs from './Sections/NavTabs.jsx';
import Intro from './Sections/Intro.jsx';

class AllServices extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <div>
          <Helmet>
            <title>Independent Land Rover Specialist Kent | Our Services</title>
            <meta
              name="description"
              content="Car Maintenence | Servicing | Welding &amp; Fabrication | Sales | Bespoke Projects | Diagnostics | Restoration | Bodywork Repair | Painting | Galvanised Chassis"
            />
            <link rel="canonical" href="https://lr-xs.co.uk/services" />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:type" content="article" />
            <meta
              property="og:title"
              content="Independent Land Rover Specialist Kent | Our Services"
            />
            <meta
              property="og:description"
              content="Car Maintenence | Servicing | Welding &amp; Fabrication | Sales | Bespoke Projects | Diagnostics | Restoration | Bodywork Repair | Painting | Galvanised Chassis"
            />
            <meta property="og:url" content="https://lr-xs.co.uk/services" />
            <meta property="og:site_name" content="LR-XS" />
          </Helmet>
        </div>
        <div>
          <Header
            rightLinks={<HeaderLinks />}
            fixed
            color="transparent"
            changeColorOnScroll={{
              height: 5,
              color: 'white',
            }}
            {...rest}
          />
        </div>
        <div>
          <Parallax small image={image}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h2 className={classes.title}><br/>Our Services</h2>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
        </div>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <Intro />
          <br />
          <NavTabs />
          <ContactSnippet />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRoot(withStyles(DefaultPageStyle)(AllServices));
