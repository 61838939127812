import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem.jsx';
import sharedStyle from 'assets/jss/material-kit-react/views/sharedSections/sharedStyle.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { Link } from 'gatsby';

function RenderListWithIcons({ ...props }) {
  const { classes, ServiceList } = props;
  return Object.keys(ServiceList).map(key => {
    const $icon = ServiceList[key][0];
    const buttonLink = ServiceList[key][1];
    return (
      <GridItem xs={12} sm={12} md={4}>
        <div className={classes.sections}>
          {/* eslint-disable-next-line */}
          <$icon className={classes.icons}> </$icon>
          <h3>{key}</h3>
          <Link to={buttonLink} className={classes.listItem}>
            <Button color="primary" size="lg">
              Find out more
            </Button>
          </Link>
        </div>
      </GridItem>
    );
  });
}

export default withStyles(sharedStyle)(RenderListWithIcons);
